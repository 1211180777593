$black: #3e3e3e;
$base-button-size: 3em;
$base-button-padding-sides: 0.25em;
$base-button-padding-bottom: 0.15em;

$base-spacing: 2em;
$bottom-spacing: 3em;

$scroll-button-padding-bottom: 0.25em;

$button-border-size: 0.1em;

@font-face {
  font-family: 'droid_sans_monoregular';
  src: url('/assets/fonts/DroidSansMono-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AsciiTextFace";
  src: url("/assets/fonts/Web_IBM_BIOS-2y.woff") format("woff"),
       url("/assets/fonts/MxPlus_IBM_BIOS-2y.ttf") format("ttf");
}

@mixin fadeable {
  transition: opacity 2s cubic-bezier(0,1,.16,1);
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

html, body {
  height: 100%;
}

body {
  font-family: "Droid Sans Mono", droid_sans_monoregular, "DejaVu Sans Mono", monospace;
  margin: 0;
  padding: 0;
}

  .scroll {
    background-color: black;
    color: white;

    footer {
      @include fadeable;

      background-color: rgba(0, 0, 0, 0.85);

      a {
        color: lightblue;
      }

      a:visited {
        color: #ba6fff;
      }

      a:active {
        color: red;
      }
    }
  }

.options-container {
  @include fadeable;

  position: absolute;
  z-index: 10;
  right: 5em;
  top: 1.5em;

  .now-playing {
    font-style: italic;
  }

  .section {
    line-height: 1.5em;
    margin-bottom: 1em;
  }

  .button {
    border-color: white;
    border-radius: 1em;
    color: white;
    font-size: 2em;
    min-width: 3em;
    padding-bottom: $scroll-button-padding-bottom;

    &.paused {
      background-color: white;
      color: $black;
    }
  }
}

.main {
  min-height: 100%;
  position: relative;
  text-align: center;

  .title {
    margin: 0;
    padding: 0.83em;
    padding-bottom: 0em;
  }

  .mtime {
    margin-top: 0.75em;
  }

  footer {
    bottom: 0;
    padding-bottom: 0.2em;
    position: absolute;
    text-align: center;
    width: 100%;
  }
}

.scroll-container {
  svg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  text {
    dominant-baseline: middle;
    font: 0.1em/normal "MxPlus IBM BIOS-2y", AsciiTextFace;
  }
}

.background-preview {
  img {
    max-height: 60em;
    max-width: 80%;
  }
}

.animation-preview {
  video {
    max-height: 60em;
    max-width: 80%;
  }
}

.ascii-preview {
  max-width: 35em;
  margin-left: auto;
  margin-right: auto;

  &.x-large {
    max-width: 70em;
  }

  &.large {
    max-width: 50em;
  }

  &.small {
    max-width: 15em;
  }

  &.x-small {
    max-width: 10em;
  }

  svg {
    width: 100%;
  }

  text {
    dominant-baseline: middle;
    font: 0.1em/normal "MxPlus IBM BIOS-2y", AsciiTextFace;
  }
}

.button-group {
  margin-top: $base-spacing;
  padding-bottom: $bottom-spacing;
}

.button {
  display: inline-block;
  text-decoration: none;
  font-variant: all-small-caps;
  font-size: $base-button-size;
  color: $black;
  font-weight: bold;
  border: $button-border-size $black solid;
  padding-bottom: $base-button-padding-bottom;
  padding-right: $base-button-padding-sides;
  padding-left: $base-button-padding-sides;

  min-width: 150px;

  &:hover {
    border-style: dashed;
  }
}

.catalog-container {
  max-width: max-content;
  margin-right: auto;
  margin-left: auto;
}

.catalog {
  text-align: left;
  padding-bottom: $base-spacing;
}

.permalink {
  color: inherit;
}
